<template>
  <div class="profileField">
    <div class="profileField__label">
      {{ label }}
    </div>

    <div
      v-if="value"
      class="profileField__value"
    >
      {{ value }}
    </div>

    <div
      v-if="note"
      class="profileField__note"
    >
      {{ note }}
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: [String, null],
      default: '',
    },

    note: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileField {
  margin: 0 0 rem(20px) 0;

  &:last-child {
    margin: 0;
  }
}

.profileField__label {
  @include note;
  margin: 0 0 rem(2px) 0;
  color: $color-grey;
}

.profileField__value {
  @include small;
}

.profileField__note {
  @include small;
  margin: 4px 0 0 0;
}
</style>