<template>
  <div v-bind:class="{
      'profileOverviewMembership': true,
      'profileOverviewMembership--overview': isOverview,
    }"
  >
    <div
      v-if="isOverview"
      class="profileOverviewMembership__title"
    >
      {{ $t('profileContract.title') }}

      <router-link
        v-bind:to="{ name: 'profile.contract' }"
        class="profileOverviewMembership__titleLink"
      >
        {{ $t('profileOverview.editLinkLabel') }}
      </router-link>
    </div>

    <profile-field
      v-bind:label="$t('profileContract.subscriptionTypeLabel')"
      v-bind:value="contract ? contract.type : $t('profileContract.subscriptionTypeInvalid')"
    />

    <profile-field
      v-if="contract.last_payment"
      v-bind:label="$t('profileContract.lastPaymentLabel')"
      v-bind:value="lastPayment"
      v-bind:note="contract.last_payment.extra_info ? $t('profileContract.lastPaymentNote', { remark: contract.last_payment.extra_info }) : ''"
    />

    <profile-field
      v-if="contract.next_payment && (contract.status === 'active' || contract.status === 'extended' || contract.status === 'cancelled')"
      v-bind:label="$t('profileContract.nextPaymentLabel')"
      v-bind:value="upcomingPayment"
    />

    <profile-field
      v-if="contract.status === 'cancelled'"
      v-bind:label="$t('profileContract.endNoticeLabel')"
      v-bind:value="endDate"
    />

    <img
      v-if="isOverview"
      v-bind:src="require('@/assets/profile/yoga-character.svg')"
      class="profileOverviewMembership__illustration"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import ProfileField from '@/components/profile/ProfileField';

export default {
  components: {
    ProfileField,
  },

  props: {
    isOverview: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    ...mapState('order', ['contract']),

    lastPayment() {
      const price = this.contract.last_payment.amount;

      return `${price} op ${moment(this.contract.last_payment.date).format('DD-MM-YYYY')}`;
    },

    upcomingPayment() {
      const price = this.contract.next_payment.amount;

      return `${price} op ${moment(this.contract.next_payment.date).format('DD-MM-YYYY')}`;
    },

    endDate() {
      return moment(this.contract.end_date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

$illustrationHeight: 107px;
$basePanelPadding: 24px;

.profileOverviewMembership {
  &--overview {
    position: relative;
    padding: 0 0 #{$illustrationHeight - $basePanelPadding} 0;
    height: 100%;
  }
}

.profileOverviewMembership__title {
  @include paragraph--bold;
  margin: 0 0 rem(24px) 0;
}

.profileOverviewMembership__titleLink {
  @include label;
  margin: 0 0 0 rem(20px);
  text-decoration: underline;
  color: $color-green;
}

.profileOverviewMembership__illustration {
  position: absolute;
  right: 86px;
  bottom: -#{$basePanelPadding};
}
</style>