<template>
  <div class="profileContract">
    <div class="profileContract__title">
      {{ $t('profileContract.title') }}
    </div>

    <profile-overview-membership
      v-bind:is-overview="false"
      class="profileContract__overviewMembership"
    />

    <profile-field
      v-if="contract.status !== 'cancelled'"
      v-bind:label="$t('profileContract.firstAvailableEndDataLabel')"
      v-bind:value="formatDate(contract.end_date)"
    />

    <profile-field
      v-if="contract.proposition?.type !== 'Coupon'"
      v-bind:label="$t('profileContract.paymentMethodLabel')"
    >
      <div
        class="profileContract__changePaymentLink"
        v-on:click="onChangePaymentClick"
      >
        {{ $t('profileContract.paymentMethodLinkLabel') }}
      </div>
    </profile-field>

    <p
      v-if="contract.status === 'cancelled'"
      class="profileContract__notice profileContract__notice--red"
    >
      {{ $t('profileContract.noticeCancelled', { contractEndDate: formatDate(contract.end_date) }) }}
    </p>

    <p
      v-if="contract.status === 'extended'"
      class="profileContract__notice profileContract__notice--green"
    >
      {{ $t('profileContract.noticeExtended') }}
    </p>

    <base-button
      v-if="contract.extendable"
      modifiers="primary"
      class="profileContract__button"
      v-bind:to="{ name: 'verlengen' }"
    >
      {{ $t('profileContract.extendButtonLabel') }}
    </base-button>

    <base-button
      v-if="contract.status !== 'processing' && contract.status !== 'cancelled'"
      modifiers="secondary"
      class="profileContract__button"
      v-bind:to="{ name: 'contract.questionnaire' }"
    >
      {{ $t('profileContract.cancelButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import ProfileOverviewMembership from '@/components/profile/ProfileOverviewMembership';
import ProfileField from '@/components/profile/ProfileField';
import BaseButton from '@/components/BaseButton';
import http from '@/services/http';

export default {
  components: {
    ProfileOverviewMembership,
    ProfileField,
    BaseButton,
  },

  computed: {
    ...mapState('order', ['contract']),
  },

  mounted() {
    this.fetchContract();
  },

  methods: {
    ...mapActions('order', ['fetchContract']),

    onChangePaymentClick() {
      http.post('/api/user/change', { terms_conditions: true })
        .then(response => {
          window.location.href = response.payment_url;
        });
    },

    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileContract__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profileContract__overviewMembership {
  margin: 0 0 rem(20px) 0;
}

.profileContract__notice {
  @include small;
  margin: 0 0 rem(20px) 0;
  padding: rem(16px);
  border-radius: 6px;

  &--red {
    background: rgba($color-error-dark, 0.1);
  }

  &--green {
    background: rgba($color-mint, 0.1);
  }
}

.profileContract__changePaymentLink {
  @include label;
  text-decoration: underline;
  color: $color-green--dark;
  cursor: pointer;
}

.profileContract__button {
  margin: 0 rem(10px) 0 0;

  &:last-child {
    margin: 0;
  }
}
</style>
